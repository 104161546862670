import Header from './components/Header'
import Hero from './components/Hero'
import About from './components/About'
import Work from './components/Work'
import WorkExamples from './components/WorkExamples'
import Skills from './components/Skills'
import Contact from './components/Contact'
// import Footer from './components/Footer'
import data from './data/portfolio-data.json'
import "./styles/_main.scss";
import Experience from './components/Experience'
import Services from './components/Services';

function App() {
  return (
    <div>
      <Header 
        navigation={Array.isArray(data.header?.navigation) ? data.header.navigation : []}/>
      <Hero title={data.hero_section?.title || "Default Title"} description={data.hero_section?.description || "Default Description"} />
      <About 
        title={data.about_section.title} 
        content={data.about_section.content}/>
      {/* <Work 
        title={data.projects_section?.title || "default title"}
        projects={Array.isArray(data.projects_section?.projects) ? data.projects_section.projects : []}/> */}
        <WorkExamples projects={Array.isArray(data.projects_section?.projects) ? data.projects_section.projects : []} />

      <Skills  
        title={data.skills_section?.title || "default title"}
        categories={Array.isArray(data.skills_section?.categories) ? data.skills_section.categories : []}
      />
      <Services />
      <Experience
        title={data.experience_section?.title || "Experience"}
        timeline={Array.isArray(data.experience_section?.timeline) ? data.experience_section.timeline : []}
      />
      <Contact title={data.contact_section.title} 
        description={data.contact_section.description}
        email={data.contact_section.email}
        linkedin={data.contact_section.linkedin}/>
    </div>
  );
}

export default App;
