import React, { useEffect, useRef, useState } from "react";
import "./../styles/_about.scss";
import img from "../assets/img/header-background.webp";

interface AboutProps {
  title: string;
  content: string[];
}

const About: React.FC<AboutProps> = ({ title, content }) => {
  const aboutRef = useRef<HTMLElement | null>(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsInView(entry.isIntersecting);
        });
      },
      { threshold: 0.3 } // Adjust threshold as needed
    );

    if (aboutRef.current) {
      observer.observe(aboutRef.current);
    }

    return () => {
      if (aboutRef.current) {
        observer.unobserve(aboutRef.current);
      }
    };
  }, []);

  return (
    <section id="about" className="about" ref={aboutRef}>
      <div className="about-container">
        <div className="about-image">
          <img rel="preload" src={img} alt="About" />
        </div>
        <div className="about-content">
          <h2 className={isInView ? "show" : ""}>{title}</h2>
          {content.map((c, index) => (
            <p key={index}>{c}</p>
          ))}
        </div>
      </div>
    </section>
  );
};

export default About;
