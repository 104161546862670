import React from "react";
import "./../styles/_services.scss";
import { Palette, Users, PersonStanding, ArrowLeftRight, Gauge, DraftingCompass, TestTubeDiagonal } from "lucide-react";

const services = [
  {
    icon: <Users size={24} />, 
    title: "Technical Leadership", 
    description: "Guide and mentor team members to achieve pragmatic solutions."
  },
  {
    icon: <ArrowLeftRight size={24} />, 
    title: "Project Alignment", 
    description: "Collaborate with and ensure alignment between design, engineering, and high-level stakeholders."
  },
  {
    icon: <Palette size={24} />, 
    title: "Design Systems", 
    description: "Build and maintain a cohesive and visually appealing design system that effectively communicates your brand."
  },
  {
    icon: <PersonStanding size={24} />, 
    title: "Accessibility (A11Y)", 
    description: "Ensure the components and pages meet or exceed team and latest WCAG standards."
  },
  {
    icon: <TestTubeDiagonal size={24} />, 
    title: "Prototyping", 
    description: "Rapidly build interactive prototypes to visualize and confirm design concept feasibility."
  },
  {
    icon: <DraftingCompass size={24} />, 
    title: "Tools", 
    description: "Build and maintain tools to support designers and developers."
  },
  {
    icon: <Gauge size={24} />, 
    title: "Performance Optimization", 
    description: "Utilize page optimization techniques to reduce page load times and keep users engaged."
  }
];

const Services: React.FC = () => {
  return (
    <section id="services" className="services skills">
    <h2 className="section-title">Services</h2>
    <div className="services-wrapper">
      {services.map((service, index) => (
        <div className="service-box" key={index}>
          <div className="icon">{service.icon}</div>
          <h3>{service.title}</h3>
          <p>{service.description}</p>
        </div>
      ))}
    </div>
  </section>
  );
};

export default Services;
