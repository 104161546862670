import React, { useState, useEffect } from "react";
import "./../styles/_header.scss";

interface NavigationItem {
  label: string;
  link: string;
}

interface HeaderProps {
  navigation: NavigationItem[];
}

const Header: React.FC<HeaderProps> = ({ navigation }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    document.body.style.overflow = menuOpen ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto"; 
    };
  }, [menuOpen]);

  useEffect(() => {
    const handleResize = () => {
      if (menuOpen) {
        setMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [menuOpen]);

  return (
    <header>
      <div className="header-container">
        <div className="logo">Matt Alter</div>
        <button
          className={`hamburger ${menuOpen ? "open" : ""}`}
          onClick={() => setMenuOpen((prev) => !prev)}
          aria-label="Toggle navigation"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <nav className={menuOpen ? "open" : ""}>
          <ul>
            {navigation.map((item, index) => (
              <li key={index} onClick={() => setMenuOpen(false)}>
                <a href={item.link}>{item.label}</a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
