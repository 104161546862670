import React from "react";
import "./../styles/_hero.scss";

interface HeroProps {
  title?: string;
  description?: string;
}

const Hero: React.FC<HeroProps> = ({ title, description }) => {
  return (
    <section className="hero">
        <div className="hero-container">
            <div className="hero-content">
                <h1>{title}</h1>
                <p>{description}</p>
            </div>
        </div>
    </section>
  );
};

export default Hero;
