import React, { useEffect, useRef, useState } from "react";
import "./../styles/_experience.scss";

interface ExperienceItem {
  year: string;
  range: string;
  role: string;
  company: string;
  description: string;
}

interface ExperienceProps {
  title: string;
  timeline: ExperienceItem[];
}

const Experience: React.FC<ExperienceProps> = ({ title, timeline }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [filledHeight, setFilledHeight] = useState(0);

  useEffect(() => {
    const entries = document.querySelectorAll(".timeline-entry");

    const handleScroll = () => {
      if (!containerRef.current) return;

      const containerRect = containerRef.current.getBoundingClientRect();
      const containerTop = containerRect.top + window.scrollY;

      let maxHeight = 0;

      entries.forEach((entry) => {
        const rect = entry.getBoundingClientRect();
        const entryBottom = rect.bottom + window.scrollY - containerTop;

        if (rect.top < window.innerHeight && rect.bottom > 0) {
          maxHeight = Math.max(maxHeight, entryBottom);
        }
      });

      setFilledHeight(Math.min(maxHeight, containerRef.current.scrollHeight));
    };

    handleScroll(); // Initialize on mount
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, [timeline]);

  // Track company occurrences
  const companyOccurrences: Record<string, number> = {};

  return (
    <section id="experience" className="skills experience">
      <h2 className="section-title">{title}</h2>
      <div
        ref={containerRef}
        className="timeline-entries"
        style={{ "--filled-height": `${filledHeight}px` } as React.CSSProperties}
      >
        {timeline.map((event, index) => {
          const count = (companyOccurrences[event.company] || 0) + 1;
          companyOccurrences[event.company] = count;

          return (
            <div
              key={index}
              className={count === 2 ? "timeline-entry-first" : `timeline-entry`}
            >
              <div className="timeline-date">
                <span className="year">{event.year}</span>
                {event.range && <span className="range"> - {event.range}</span>}
              </div>
              <div className="timeline-content">
                <h3 className="timeline-title">
                  {event.role} - {event.company}
                </h3>
                <p className="timeline-description">{event.description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Experience;
