import React, { useState, useEffect, KeyboardEvent, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import '../styles/_workexamples.scss';

interface Project {
  title: string;
  category: string;
  image: string;
  description: string;
  link: string;
  tags: string[];
}

interface WorkBentoProps {
  projects: Project[];
  className?: string;
}

const WorkBento: React.FC<WorkBentoProps> = ({ projects = [], className = '' }) => {
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const lastActiveElement = useRef<HTMLElement | null>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  // Store the last focused element and handle body scroll lock
  // Store scroll position in a ref to persist between renders
  const scrollPosRef = useRef(0);

  useEffect(() => {
    if (selectedProject) {
      // Store current scroll position and active element
      lastActiveElement.current = document.activeElement as HTMLElement;
      scrollPosRef.current = window.scrollY;
      
      // Lock body scroll
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollPosRef.current}px`;
      document.body.style.width = '100%';
      
      // Set focus to close button after modal opens
      setTimeout(() => {
        closeButtonRef.current?.focus();
      }, 100);
    } else {
      // Restore body scroll
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
      
      // Restore scroll position
      window.scrollTo(0, scrollPosRef.current);
      
      // Restore focus to the last active element
      lastActiveElement.current?.focus();
    }

    return () => {
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.width = '';
    };
  }, [selectedProject]);

  // Handle focus trap in modal
  const handleTabKey = (e: KeyboardEvent) => {
    if (!modalRef.current || e.key !== 'Tab') return;

    const focusableElements = modalRef.current.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    const firstElement = focusableElements[0] as HTMLElement;
    const lastElement = focusableElements[focusableElements.length - 1] as HTMLElement;

    if (e.shiftKey) {
      if (document.activeElement === firstElement) {
        e.preventDefault();
        lastElement.focus();
      }
    } else {
      if (document.activeElement === lastElement) {
        e.preventDefault();
        firstElement.focus();
      }
    }
  };

  // Handle escape key to close modal
  const handleEscapeKey = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && selectedProject) {
      setSelectedProject(null);
    }
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>, project: Project) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      setSelectedProject(project);
    }
  };

  const ProjectCard: React.FC<{ project: Project; index: number }> = ({ project, index }) => (
    <motion.div
      role="button"
      tabIndex={0}
      className={`bento-gallery__card ${
        index === 0 ? 'bento-gallery__card--featured' : 
        index === 3 ? 'bento-gallery__card--wide' : ''
      }`}
      // whileHover={{ scale: 1.02 }}
      // whileTap={{ scale: 0.98 }}
      onClick={() => setSelectedProject(project)}
      onKeyDown={(e) => handleKeyPress(e, project)}
      aria-label={`View details for ${project.title}`}
    >
      <img
        src={project.image}
        alt={project.title}
        className="bento-gallery__card-image"
        loading="lazy"
      />
      <div className="bento-gallery__card-content">
        <span className="bento-gallery__card-category">{project.category}</span>
        <h3 className="bento-gallery__card-title">{project.title}</h3>
        <div className="bento-gallery__card-tags">
          {project.tags.map(tag => (
            <span key={tag} className="bento-gallery__tag">
              {tag}
            </span>
          ))}
        </div>
      </div>
    </motion.div>
  );

  const ProjectModal: React.FC<{ project: Project }> = ({ project }) => (
    <motion.div
      ref={modalRef}
      className="bento-gallery__modal-content"
      initial={{ scale: 0.5, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.5, opacity: 0 }}
      onClick={e => e.stopPropagation()}
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
      onKeyDown={(e) => {
        handleTabKey(e);
        handleEscapeKey(e);
      }}
    >
      <button
        ref={closeButtonRef}
        onClick={(e) => {
          e.stopPropagation();
          setSelectedProject(null);
        }}
        className="bento-gallery__modal-close"
        aria-label="Close modal"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="18" y1="6" x2="6" y2="18" />
          <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
      </button>
      
      <img
        src={project.image}
        alt={project.title}
        className="bento-gallery__modal-image"
      />
      <div className="bento-gallery__modal-body">
        <span className="bento-gallery__modal-category">{project.category}</span>
        <h3 id="modal-title" className="bento-gallery__modal-title">{project.title}</h3>
        <p className="bento-gallery__modal-description">{project.description}</p>
        <div className="bento-gallery__modal-tags">
          {project.tags.map(tag => (
            <span key={tag} className="bento-gallery__tag">
              {tag}
            </span>
          ))}
        </div>
        <a 
          href={project.link}
          target="_blank"
          rel="noopener noreferrer"
          className="bento-gallery__modal-link"
        >
          View Project
        </a>
      </div>
    </motion.div>
  );

  if (!projects.length) {
    return null;
  }

  return (
    <section id="work" className={`bento-gallery ${className}`}>
      <h2 className="section-title">Recent Work</h2>
      
      <div className="bento-gallery__grid">
        {projects.map((project, index) => (
          <ProjectCard key={project.title} project={project} index={index} />
        ))}
      </div>

      <AnimatePresence>
        {selectedProject && (
          <motion.div
            className="bento-gallery__modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setSelectedProject(null)}
          >
            <ProjectModal project={selectedProject} />
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};

export default WorkBento;