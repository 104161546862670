import React from "react";
import "./../styles/_skills.scss";

interface Category {
  title: string;
  skills: string[];
}

interface SkillsProps {
  title: string;
  categories: Category[];
}

const Skills: React.FC<SkillsProps> = ({ title, categories }) => {
  return (
    <section id="skills" className="skills">
      <h2 className="section-title">Skills</h2>
        <div className="skills-container">
            {categories.map((category, index) => (
                <div key={index} className="skill-category">
                    <h3>{category.title}</h3>
                    <ul className="skill-list">
                    {category.skills.map((skill, i) => (
                        <li key={i}>{skill}</li>
                    ))}
                    </ul>
                </div>
            ))}
        </div>
    </section>
  );
};

export default Skills;
