import React from "react";
import "./../styles/_contact.scss";

interface ContactProps {
  title: string;
  description: string;
  email: string;
  linkedin: string;
}

const Contact: React.FC<ContactProps> = ({ title, description, linkedin }) => {
  return (
    <section id="contact" className="contact">
        <div className="contact-container">
            <h2>{title}</h2>
            <span>{description} <a href="mailto:mattbalter@gmail.com" className="social-link">EMAIL</a> | <a href={linkedin} target="_blank" rel="noopener noreferrer" className="social-link">
              LINKEDIN
            </a></span>
        </div>
    </section>
  );
};

export default Contact;
